<style scoped>

.avatar  {
    position: relative
}

.hoverparent:hover .pencil {  /* display pencil when hovered over the container */
    display: block;
}

.pencil {
    position: absolute;
    display:none; /* do not display by default */
}
</style>

<template>

    <!-- make distinction between avatar and banner -->
    <form v-if="type=='avatar'" style="text-align:center;" class="hoverparent">

        <v-avatar :size="size" class="avatar">

            <!-- show image if there is one -->
            <img v-if="innerImg" :src="innerImg" alt="logo">

            <!-- otherwise show empty circle -->
            <div v-else style="border-style: solid; border-radius: 25px; width:100%; height: 100%;"></div>

            <!-- if not readonly. show a pencil to edit -->
            <v-icon class="pencil" v-if="!readonly" :style="pencilStyle" @click="change">mdi-pencil</v-icon>

        </v-avatar>

        <input type="file" ref="fileInput" @change="onFileChange($event)" style="display: none;"/>

    </form>

 <!-- make distinction between avatar and banner -->
    <form v-else style="text-align:center;" class="hoverparent">

            <!-- show banner if there is one -->
            <img v-if="innerImg" style="width: 100%; height: 100%;" :src="innerImg" alt="banner">

            <!-- otherwise show empty box -->
            <div v-else style="border-style: solid; border-radius: 25px; width:100%; height: 100%;"></div>

            <!-- if not readonly. show a pencil to edit -->
             <v-icon class="pencil" v-if="!readonly" :style="pencilStyle" @click="change" style="right: 15px; top:15px">mdi-pencil</v-icon>
        
             <input type="file" ref="fileInput" @change="onFileChange($event)" style="display: none;"/>
       
    </form>
</template>

<script>
export default {
  props: {
    src: String,
    type: {
        type: String,
        default: 'avatar'
    },
    readonly: Boolean,
    size: {
        type: String,
        default: "72"
    }
  },
  watch: {
    src: {
        handler(newVal) { 
            this.innerImg = newVal 
        },
        immediate: true
    }
  },
  data() {
    return {
        innerImg: ""
    }
  },
  computed: {
    pencilStyle() { return !this.innerImg ? { display:"block" } : null }
  },
  methods: {
      change() {
          if (this.readonly) return;
          this.$refs.fileInput.click()
      },
    onFileChange(e) {
      if (this.loading) return
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }

      this.toBase64(files[0]).then((base64data) => {
          this.$emit('change', base64data)
          this.innerImg = base64data
      })
    },
    async toBase64 (file) { 
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        })
    }
  }
}
</script>